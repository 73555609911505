<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="d_f">
      <div class="flex_1">
        <!--基本信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>基本信息</div>
          </div>
          <el-row class="top_info">
            <el-col :span="8"><span>订单编号：{{info.order.orderNumber}}</span></el-col>
            <el-col :span="8"><span v-if="info.order.orderStatus">订单状态：{{staArr[info.order.orderStatus].name}}</span></el-col>
            <el-col :span="8"><span>总票数：{{info.order.totalPurchaseNumber || '--'}}</span></el-col>
          </el-row>
          <el-row class="top_info">
            <el-col :span="8"><span>总金额：{{info.order.totalSellPrice ? info.order.totalSellPrice/100 : 0}}（元）</span></el-col>
            <el-col :span="8"><span>预定消费日期：{{info.order.bookUseDate ? info.order.bookUseDate.split(' ')[0] : '--'}}</span></el-col>
            <el-col :span="8"><span>支付方式：{{info.order.payTypeId ? getPayType(info.order.payTypeId ) : '--'}}</span></el-col>
          </el-row>
          <el-row class="top_info">
            <el-col :span="8"><span>支付单号：{{info.order.payNumber || '--'}}</span></el-col>
            <el-col :span="8"><span>订单创建时间：{{info.order.creatorTime}}</span></el-col>
          </el-row>

          <!--按钮-->
          <div class="ch_btn mt" v-if="info.order.orderStatus < 2">
            <div class="cur_p" @click="goPay">前往支付</div>
          </div>
        </div>
        <!--预订人信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>预订人信息</div>
          </div>
          <div class="t_r_info">
            <span>预订人姓名：{{info.order.reservationPersonName}}</span>
            <span class="ml">预订人手机号：{{info.order.reservationPersonTelephone}}</span>
          </div>
        </div>
        <!--游客信息-->
        <div class="uc_con">
          <div class="uc_title d_f ali_c flex_1">
            <div class="uc_green"></div>
            <div>游客信息</div>
          </div>

          <el-table
            ref="multipleTable"
            :data="info.details"
            stripe
            :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column label="商品名称" align="center">
              <template #default="scope">{{ scope.row.commodityName }}</template>
            </el-table-column>
            <el-table-column prop="visitorName" label="游客姓名" align="center"> </el-table-column>
            <el-table-column prop="address" label="证件类型" align="center" show-overflow-tooltip>

              <template #default="scope"><span>{{getZJlX(scope.row.credentialsType)}}</span></template>
            </el-table-column>
            <el-table-column prop="certificateNumber" label="证件号码" align="center" show-overflow-tooltip></el-table-column>
          </el-table>
        </div>
      </div>
      <div class="td_r"></div>
    </div>
    <div class="td_rp p_f">
      <div class="tdr_title p_r t_a">
        <span>订单详情</span>
        <div class="p_a"></div>
      </div>
      <div class="ch_btn">
        <div @click="$router.go(-1)">返回上一级</div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "orderDetails",
    data() {
      return {
        info:{details:[],orderDetails:[],order:{}},
        cardArr:[],
        payArr:[],
        staArr:[
          {value:0,name:'未支付'},
          {value:1,name:'待支付'},
          {value:2,name:'已取消'},
          {value:3,name:'订单关闭'},
          {value:4,name:'出票失败'},
          {value:5,name:'出票成功'},
        ],
        seconds:null,
        m:'',
        interval:null,
        cPage:1,
      }
    },
    created() {
      this.queryDisPayType();
      this.queryWinCredentials();
      this.getDetail();
    },
    onBeforeRouteLeave(){
      window.clearInterval(this.interval);
      this.interval = null;
    },
    methods: {
      //弹窗确认
      save(){

      },
      getPayType(id){
        let str ='';
        this.payArr.map(item => {
          if(id == item.value){
            str = item.name
          }
        });
        return str
      },
      //支付方式
      queryDisPayType(){
        this.$api.queryDisPayType().then(res => {
          this.payArr = res.data;
        })
      },
      //获取证件类型
      queryWinCredentials(){
        this.$api.queryWinCredentials().then(res => {
          this.cardArr = res.data;
        })
      },
      getZJlX(type){
        let name = '';
        this.cardArr.map(item => {
          if(item.value == type){
            name = item.name
          }
        });
        return name
      },
      getDetail(){
        this.$api.queryOrderGroup({orderId :this.$route.query.orderId}).then(res => {
          this.info = res.data;
        })
      },
      goPay(){
        this.$router.push({path:'/pay',query:{id:this.$route.query.orderId,num:this.$route.query.num}});
      }
    }
  }
</script>

<style scoped lang="scss">
  .ch_btn.mt{
    margin-top: 30px;
  }
  :v-deep .el-dialog__body{
    padding: 0;
  }
  .td_pop_con{
    img{
      margin-bottom: 34px;
      width: 202px;
      height: 202px;
    }
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin-bottom: 73px;
  }
  .t_r_info{
    .ml{
      margin-left: 135px;
    }
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .ch_btn{
    div{
      display: inline-block;
      &.ac{
        margin-left: 42px;
      }
    }
    text-align: center;
  }
  .top_info{
    margin-bottom: 20px;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
  }
  .uc_title{
    margin-bottom: 38px;
  }
</style>
